import "mdb-vue-ui-kit/css/mdb.min.css";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import VueGtag from "vue-gtag";
import VueApexCharts from "vue3-apexcharts";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      const hostname = window.location.hostname;
      const vvvTestHostName = "app-cs-dashboardapp-dev.azurewebsites.net";
      const vvvHostName = "veryviralvisuals";
      const loginName = "Login";
      const vvvLoginName = "VVVLogin";

      let redirectUrl = loginName;

      if (hostname === "localhost") {
        redirectUrl = loginName;
      } else if (hostname === vvvHostName || hostname === vvvTestHostName) {
        router.push({ name: vvvLoginName });
        redirectUrl = vvvLoginName;
      }

      window.location.href = redirectUrl;
    }
    return error;
  }
);

const gTagId = window.location.hostname.includes("veryviralvisuals")
  ? "G-TPDY9VYQ4L"
  : "G-55BWLZ0TW1";
const app = createApp(App);
app.use(
  VueGtag,
  {
    config: { id: gTagId },
  },
  router
);

app.use(router).mount("#app");
const pinia = createPinia();
app.use(pinia);
pinia.use(piniaPluginPersistedstate);
app.use(VueApexCharts);
